/**
  * Facebook tracking pixels
  * Dynamic pixels, must be placed in JS rather than the
  * Nunjucks file for them to fire correctly.
*/

// Receipt page - Track add to cart
if (window.location.href.includes('cart/details')) {
  const transaction = window.dataLayer.find(function (e) { return e.hasOwnProperty('transactionId') })
  if (transaction) {
    setTimeout(() => {
      for (const product of transaction.transactionProducts) {
        fbq('track', 'AddToCart', {
          content_ids: product.name,
          value: product.price,
          currency: 'GBP'
        })
      }
    }, 10)
  }
}

// Receipt page - Track purchase
if (window.location.href.includes('cart/receipt')) {
  const transaction = window.dataLayer.find(function (e) { return e.hasOwnProperty('transactionId') })
  if (transaction) {
    const purchaseContents = transaction.transactionProducts.map(product => ({
      id: product.name,
      quantity: product.quantity
    }))
    setTimeout(() => {
      fbq('track', 'Purchase', {
        value: transaction.transactionTotal,
        currency: 'GBP',
        contents: purchaseContents
      })
    }, 5)
  } else {
    console.log('no transaction')
  }
}
